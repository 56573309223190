import React, { useContext } from "react";
import Carousel from "~components/Carousel";
import { DocumentContext } from "~context/DocumentContext";

const ProductFeaturesPDP = () => {
  const { device } = useContext(DocumentContext);

  const icons = [
    {
      img: `/uploads/pdp-calf/product-icons/take-care-out-there-thumb.svg`,
      text: `Our products are thoughtfully designed, right down to the care instructions. Find out how to get those good feels for longer. (Clicks through to care instructions)`
    },
    {
      img: `/uploads/pdp-calf/product-icons/fewer-washes.svg`,
      text: `Paire’s supreme Cool Blend™ of Australian Merino wool and organic combed cotton means you don’t need to wash your socks quite as often as you would with a regular pair. More wear time. Fewer washes. Water saved.`
    },
    {
      img: `/uploads/pdp-calf/product-icons/seamless-toe.svg`,
      text: `Hand-sewn for maximum comfort. No blisters or skin irritations. No bunching at the toes. And cloud cushioning designed to hug your sole and put extra pep in your step.`
    }
  ];

  //

  return (
    <div className="w-full relative bg-off-white">
      <section className="grid xs:flex xs:flex-col">
        <div className="grid-end-12 mt-20 mb-10">
          <h2 className="f3 text-center">Why Paire is the comfiest sock?</h2>
        </div>

        {/* <div className="flex gap-10 grid-end-12 mt-10 mb-20"> */}

        {device !== `mobile` &&
          icons?.[0] &&
          icons.map((icon, index) => {
            const key = `product-feature-pdp-${index}`;

            return (
              <div
                key={key}
                className="grid-end-4 text-left inline-flex flex-col flex-1 items-center px-v4"
              >
                <img src={icon.img} alt="icon" className="max-w" />
                <p className="mt-v2 mb-v6 b2">{icon.text}</p>
              </div>
            );
          })}

        {device === `mobile` && icons?.[0] && (
          <div className="my-5 w-full">
            <Carousel
              keyPrefix="product-features"
              items={icons.map((icon, index) => {
                const key = `product-feature-${index}`;

                return (
                  <div
                    className="w-full relative text-left flex flex-col items-center"
                    key={key}
                  >
                    <img src={icon.img} alt="icon" className="" />
                    <p className="b2 mt-10 w-4/5 text-center">{icon.text}</p>
                  </div>
                );
              })}
              withBullets
            />
          </div>
        )}
      </section>
    </div>
  );
};

export default ProductFeaturesPDP;
