import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { DocumentContext } from "~context/DocumentContext";

import Cross from "~components/svg/Cross";

const SizeChart = ({ chart, setChart }) => {
  const {
    sanitySizeChart: { sizes }
  } = useStaticQuery(graphql`
    query {
      sanitySizeChart {
        sizes {
          _key
          title
          sml
          med
          lrg
          xlrg
        }
      }
    }
  `);

  const { device } = useContext(DocumentContext);

  return (
    <>
      {chart && (
        <div
          className="size-chart w-screen h-screen fixed top-0 left-0 right-0 bottom-0 z-50 overflow-y-scroll flex items-center justify-center"
          style={{ backgroundColor: `rgba(0,0,0,0.5)` }}
        >
          <div className="size-chart__table animation-appear-slow animation-delay-1 relative bg-silver">
            <span className="good-feels sm:relative absolute block normal-case pxl-menu">
              Good feels for all
            </span>

            <button
              type="button"
              className="w-12 h-12 absolute top-0 right-0 mt-2 mr-3 p-2"
              onClick={() => setChart(false)}
            >
              <Cross className="w-full h-full" color="black" />
            </button>

            <table>
              <thead>
                <tr>
                  <td></td>
                  {device === `desktop` ? (
                    <>
                      <th className="table-cell pxl-button">SML</th>
                      <th className="table-cell pxl-button">MED</th>
                      <th className="table-cell pxl-button">LRG</th>
                      <th className="table-cell pxl-button">XLRG</th>
                    </>
                  ) : (
                    <>
                      <th className="table-cell pxl-button">S</th>
                      <th className="table-cell pxl-button">M</th>
                      <th className="table-cell pxl-button">L</th>
                      <th className="table-cell pxl-button">XL</th>
                    </>
                  )}
                </tr>
              </thead>

              <tbody>
                {sizes.map(row => {
                  return device === `desktop` ? (
                    <tr key={row._key + row.title}>
                      <th className="table-cell pxl-button">{row.title}</th>
                      <td>
                        {row.sml.map(size => (
                          <div className="table-cell pxl-caption">{size}</div>
                        ))}
                      </td>
                      <td>
                        {row.med.map(size => (
                          <div className="table-cell pxl-caption">{size}</div>
                        ))}
                      </td>
                      <td>
                        {row.lrg.map(size => (
                          <div className="table-cell pxl-caption">{size}</div>
                        ))}
                      </td>
                      <td>
                        {row.xlrg.map(size => (
                          <div className="table-cell pxl-caption">{size}</div>
                        ))}
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <th className="table-cell pxl-button">{row.title}</th>
                      <td>
                        {row.sml.map(size => {
                          return (
                            <div className="table-cell pxl-caption">{size}</div>
                          );
                        })}
                      </td>
                      <td>
                        {row.med[1] ? (
                          <div className="table-cell pxl-caption flex-col">
                            <span className="border-b">{row.med[0]}</span>
                            <span>{row.med[1]}</span>
                          </div>
                        ) : (
                          row.med.map(size => (
                            <div className="table-cell pxl-caption">{size}</div>
                          ))
                        )}
                      </td>
                      <td>
                        {row.lrg[1] ? (
                          <div className="table-cell pxl-caption flex-col">
                            <span className="border-b">{row.lrg[0]}</span>
                            <span>{row.lrg[1]}</span>
                          </div>
                        ) : (
                          row.lrg.map(size => (
                            <div className="table-cell pxl-caption">{size}</div>
                          ))
                        )}
                      </td>
                      <td>
                        {row.xlrg.map(size => {
                          return (
                            <div className="table-cell pxl-caption">{size}</div>
                          );
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default SizeChart;
