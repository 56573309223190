import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import lottie from "lottie-web";

const LottieAnimation = ({
  animation,
  animationPublicURL,
  enterFrameCallback,
  completeCallback,
  animationOptions,
  className,
  delay,
  style,
  startLoading,
  id,
  playing
}) => {
  const ref = useRef();

  const [loaded, setLoaded] = useState(false);
  const [lottieAnimationObj, setLottieAnimationObj] = useState(null);

  useEffect(() => {
    if (lottieAnimationObj !== null) {
      if (playing) {
        lottieAnimationObj.play();
      } else {
        lottieAnimationObj.pause();
      }
    }
  }, [playing, lottieAnimationObj]);

  const init = () => {
    setTimeout(async () => {
      let lottieAnimation;
      // We either load the animation straight away
      if (animation) {
        lottieAnimation = lottie.loadAnimation({
          container: ref.current,
          animationData: animation,
          renderer: `svg`,
          loop: true,
          autoplay: true,
          ...animationOptions
        });
        lottieAnimation.addEventListener(`enterFrame`, () => {
          enterFrameCallback();
        });

        lottieAnimation.addEventListener(`complete`, () => {
          completeCallback();
        });

        setLottieAnimationObj(lottieAnimation);
      } else if (animationPublicURL) {
        // Or we fetch it from publicURL and then load it
        fetch(animationPublicURL)
          .then(response => response.json())
          .then(lottieJSON => {
            lottieAnimation = lottie.loadAnimation({
              container: ref.current,
              animationData: lottieJSON,
              renderer: `svg`,
              loop: true,
              autoplay: true,
              ...animationOptions
            });

            lottieAnimation.addEventListener(`enterFrame`, () => {
              enterFrameCallback();
            });

            lottieAnimation.addEventListener(`complete`, () => {
              completeCallback();
            });

            setLottieAnimationObj(lottieAnimation);
          });
      } else {
        throw new Error(
          `LottieAnimation needs either animation object or publicURL to fetch animation object`
        );
      }
    }, delay);
  };

  useEffect(() => {
    if (startLoading && !loaded && ref.current) {
      init();
      setLoaded(true);
    }
  }, [startLoading, ref]);

  return <div id={id} ref={ref} style={style} className={className} />;
};

LottieAnimation.propTypes = {
  animation: PropTypes.shape({}),
  animationPublicURL: PropTypes.string,
  id: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  animationOptions: PropTypes.shape({}),
  enterFrameCallback: PropTypes.func,
  completeCallback: PropTypes.func,
  className: PropTypes.string,
  delay: PropTypes.number,
  startLoading: PropTypes.bool,
  playing: PropTypes.bool
};

LottieAnimation.defaultProps = {
  enterFrameCallback: () => {},
  completeCallback: () => {},
  animationOptions: {},
  style: {},
  animation: null,
  animationPublicURL: null,
  className: ``,
  delay: 0,
  startLoading: true,
  playing: true
};

export default LottieAnimation;
