/* eslint-disable camelcase */
/* eslint-disable react/prop-types */

import React, { useContext, useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import Img from "gatsby-image";
import { graphql, Link } from "gatsby";
import { AppContext } from "~context/AppContext.jsx";
import { DocumentContext } from "~context/DocumentContext";
import Accordion from "~components/Accordion";
import Button from "~components/Button";
import Carousel from "~components/Carousel";
import CustomerReview from "~components/CustomerReview";
import Footer from "~components/Footer";
import GivePaireCTA from "~components/GivePaireCTA";
import Layout from "~components/Layout";
import LottieAnimation from "~components/LottieAnimation";
import ProductFeaturesPDP from "~components/ProductFeaturesPDP";
import ProductOverview from "~components/ProductOverview";
import SEO from "~components/SEO";
import SizeChart from "~components/SizeChart.jsx";
import SuggestedProducts from "~components/SuggestedProducts";
import Arrow from "~components/svg/Arrow";
import { pushProductEvent } from "~utils/analytics";
import { useKeyPress } from "~utils/hooks";
import { getSelectableOptions, getVariantByOptions } from "~utils/shopify";

import animationData from "~data/lottie/good-feels-wave.json";

const ProductPage = ({ data, location }) => {
  const {
    addToCart,
    inventoryCache,
    inventoryEnabled,
    inventoryFetched
  } = useContext(AppContext);
  const { device } = useContext(DocumentContext);

  const [cartProduct, setCartProduct] = useState(null);
  const [chart, setChart] = useState(false);
  const [outOfStockVariants, setOutOfStockVariants] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  //

  const cms = data.sanityProduct;
  const options = getSelectableOptions(cms.shopifyProduct);
  const colourOptions = options?.Colour;
  const sizeOptions = options?.Size;

  const selectOption = (key, value) => {
    setSelectedOptions({
      ...selectedOptions,
      [key]: value
    });
  };

  //

  /**
   * ---------------------------------------------------------------------------
   * useEffect []
   * Loads on page render and sets a default variant based on the first set
   * of available selectable Shopify options.
   */
  useEffect(() => {
    const defaultOptions = {};

    Object.keys(options).forEach(optionKey => {
      defaultOptions[optionKey] = options[optionKey]?.[0];
    });

    setSelectedOptions(defaultOptions);
  }, []);

  /**
   * ---------------------------------------------------------------------------
   * useEffect [selectedOptions]
   * Executes every time a user changes a selectable option. This will create
   * a cartProduct, which contains the cartProduct.variant object required for
   * checkout.
   */
  useEffect(() => {
    setCartProduct({
      ...cms.shopifyProduct,
      variant: getVariantByOptions(cms.shopifyProduct, selectedOptions),
      variants: null
    });
  }, [selectedOptions]);

  /**
   * ---------------------------------------------------------------------------
   * [useEffect] inventory
   * Executes on inventoryCache refresh, which usually fires once per site load.
   */

  useEffect(() => {
    if (!inventoryCache) {
      return;
    }

    const unstockedVariants = [];

    Object.keys(inventoryCache).forEach(variantId => {
      if (inventoryCache[variantId] <= 0) {
        unstockedVariants.push(variantId);
      }
    });

    setOutOfStockVariants(unstockedVariants);
  }, [inventoryCache]);

  /**
   * ---------------------------------------------------------------------------
   * [useEffect] cartProduct
   * Executes whenever the cartProduct is set. Useful for adding analytics
   * methods or additional hooks that fire on a user selecting a product.
   */
  useEffect(() => {
    if (!cartProduct) {
      return;
    }

    pushProductEvent(`productView`, cartProduct);
  }, [cartProduct]);

  //

  let colourOptionJSX = <></>;

  if (colourOptions?.[0]) {
    colourOptionJSX = (
      <article className="w-full relative block sm:mb-4">
        <h4 className="mt-v2 mb-v05 caption sm:mb-2">
          Colour
          {selectedOptions?.Colour ? `: ${selectedOptions.Colour}` : ``}
        </h4>

        <div className="w-full flex">
          {colourOptions.map(option => {
            let backgroundStyle = {};
            let hexes;
            let selected = false;

            data.allSanityColourMap.edges.forEach(({ node }) => {
              if (hexes) {
                return;
              }

              if (node.title.toLowerCase() === option.toLowerCase()) {
                ({ hexes } = node);
              }
            });

            if (cartProduct?.variant?.selectedOptions) {
              cartProduct.variant.selectedOptions.forEach(selectedOption => {
                if (selectedOption?.name && selectedOption.name === `Colour`) {
                  selected = selectedOption.value === option;
                }
              });
            }

            if (hexes?.[0]) {
              if (hexes.length === 3) {
                backgroundStyle = {
                  background: `linear-gradient(to right, ${hexes[0]} 33.333%, ${hexes[1]} 33.333%, ${hexes[1]} 66.667%, ${hexes[2]} 66.667%)`
                };
              } else {
                backgroundStyle = {
                  backgroundColor: hexes[0]
                };
              }
            }

            return (
              <button
                key={option}
                type="button"
                className={`product-page__color-picker ${
                  selected ? `selected border-black` : ``
                } w-8 h-8 mr-2 relative block rounded-full`}
                onClick={() => selectOption(`Colour`, option)}
              >
                <div
                  className="product-page__color-picker__bg w-full h-full relative rounded-full overflow-hidden pointer-events-none"
                  style={backgroundStyle}
                ></div>
              </button>
            );
          })}
        </div>
      </article>
    );
  }

  let sizeOptionJSX = <></>;

  if (sizeOptions?.[0]) {
    sizeOptionJSX = (
      <article className="w-full relative block sm:mb-5">
        <div className="w-full flex items-center justify-between mt-v2 mb-v05 sm:mb-2">
          <h4 className="caption">
            Size
            {selectedOptions?.Size ? `: ${selectedOptions.Size}` : ``}
          </h4>

          <h4 className="caption cursor-pointer">
            <button
              type="button"
              className="underline"
              onClick={() => setChart(true)}
            >
              Unisex Size Chart
            </button>
          </h4>
        </div>

        <div className="w-full relative">
          <div className="w-full relative flex items-center justify-between sm:gap-2">
            {sizeOptions.map(option => {
              let selected = false;

              if (cartProduct?.variant?.selectedOptions) {
                cartProduct.variant.selectedOptions.forEach(selectedOption => {
                  if (selectedOption?.name && selectedOption.name === `Size`) {
                    selected = selectedOption.value === option;
                  }
                });
              }

              let buttonText;

              switch (option.toLowerCase()) {
                case `s`:
                  buttonText = `SML`;
                  break;

                case `m`:
                  buttonText = `MED`;
                  break;

                case `l`:
                  buttonText = `LRG`;
                  break;

                case `xl`:
                default:
                  buttonText = `XLG`;

                  break;
              }

              return (
                <button
                  key={option}
                  className={`product-page__option ${
                    selected ? `bg-hit-pink` : `bg-off-white`
                  } border-black`}
                  type="button"
                  onClick={() => selectOption(`Size`, option)}
                >
                  <span className="f-button">{buttonText}</span>
                </button>
              );
            })}
          </div>
        </div>
      </article>
    );
  }

  //

  let stocked = false;

  if (!inventoryEnabled) {
    stocked = true;
  } else if (
    cartProduct?.variant?.id &&
    inventoryFetched &&
    !outOfStockVariants.includes(cartProduct.variant.id)
  ) {
    stocked = true;
  }

  //

  const close = () => {
    setChart(false);
  };

  const escKeyPressed = useKeyPress(`Escape`);

  useEffect(() => {
    close();
  }, [escKeyPressed]);

  //

  return (
    <>
      <SEO
        customTitle={cms.title}
        customDescription={cms.tagline}
        customKeywords=""
        path={location.pathname}
      />

      <Layout className="product-page w-full relative bg-off-white">
        <SizeChart chart={chart} setChart={setChart} />

        <section className="grid">
          <article className="grid-end-8 flex flex-col pt-v4">
            {device === `desktop` &&
              cms?.availableColours?.[0] &&
              cms.availableColours.map(colourBlock => {
                let key = ``;

                if (colourBlock?.colourRef?.title) {
                  key = `product-gallery-${colourBlock.colourRef.title}`;
                }

                if (
                  !selectedOptions?.Colour ||
                  selectedOptions.Colour.toLowerCase() !==
                    colourBlock?.colourRef?.title?.toLowerCase()
                ) {
                  return <React.Fragment key={key}></React.Fragment>;
                }

                return (
                  <ul
                    key={key}
                    className="animation-appear-right animation-delay-2"
                  >
                    {colourBlock?.gallery?.[0] &&
                      colourBlock.gallery.map(
                        (galleryItem, galleryItemIndex) => {
                          const imageKey = `${key}-${galleryItemIndex}`;

                          return (
                            <figure
                              key={imageKey}
                              className="animation-appear-down-slow w-full relative block mb-v2"
                              style={{
                                animationDelay: `${galleryItemIndex * 100 +
                                  100}ms`
                              }}
                            >
                              <Img
                                className="relative block rounded-lg"
                                fluid={galleryItem.asset.fluid}
                                alt={
                                  galleryItem?.altText
                                    ? galleryItem.altText
                                    : imageKey
                                }
                              />
                            </figure>
                          );
                        }
                      )}
                  </ul>
                );
              })}
          </article>

          {device !== `desktop` && cartProduct && (
            <article className="pt-20 grid-start-1 grid-end-12">
              {device !== `desktop` && (
                <Link
                  to="/products"
                  className="relative flex items-center self-start mb-v05 xs:mb-5"
                >
                  <Arrow className="product-page__sticky__arrow" />
                  <span className="caption">BACK TO SHOP</span>
                </Link>
              )}

              {cms?.availableColours?.[0] &&
                cms.availableColours.map(colourBlock => {
                  let key = ``;

                  if (colourBlock?.colourRef?.title) {
                    key = `product-gallery-${colourBlock.colourRef.title}`;
                  }

                  if (
                    selectedOptions?.Colour?.toLowerCase() !==
                    colourBlock?.colourRef?.title?.toLowerCase()
                  ) {
                    return <div key={key} className="hidden"></div>;
                  }

                  return (
                    <div key={key}>
                      {colourBlock?.gallery?.[0] && (
                        <Carousel
                          keyPrefix="product-gallery"
                          items={colourBlock.gallery.map(
                            (galleryItem, galleryItemIndex) => {
                              const imageKey = `${key}-${galleryItemIndex}`;

                              return (
                                <figure
                                  key={imageKey}
                                  className="w-full relative block"
                                >
                                  <Img
                                    className="w-full relative block rounded-lg"
                                    fluid={galleryItem.asset.fluid}
                                    alt={
                                      galleryItem?.altText
                                        ? galleryItem.altText
                                        : imageKey
                                    }
                                  />
                                </figure>
                              );
                            }
                          )}
                          withBullets
                        />
                      )}
                    </div>
                  );
                })}
            </article>
          )}

          {cartProduct && (
            <div className="product-page__sticky animation-appear-left animation-delay-2 grid-end-4 sm:grid-end-12 sm:grid-start-1 h-screen sm:h-auto sticky sm:relative top-0 sm:top-auto left-0 sm:left-auto overflow-y-scroll sm:overflow-auto pt-v5 pb-v5">
              <article className="flex flex-col items-center pl-4 sm:pl-0">
                {device === `desktop` && (
                  <Link
                    to="/products"
                    className="relative flex items-center self-start mb-v05 xs:mb-5"
                  >
                    <Arrow className="product-page__sticky__arrow" />
                    <span className="caption">BACK TO SHOP</span>
                  </Link>
                )}

                {device !== `desktop` && (
                  <div className="w-full mb-3">{colourOptionJSX}</div>
                )}

                <h1 className="w-full mt-v1 f4 font-bold mb-v1">{cms.title}</h1>

                {cartProduct?.variant && (
                  <div className="w-full relative flex items-center justify-start mb-v05 sm:mb-5">
                    <h3 className="f5">${cartProduct.variant.price}</h3>

                    {cms?.packSize && (
                      <div className="relative flex items-center justify-center ml-3 pt-2 pr-2 xs:pr-4 pb-1 xs:pb-1 pl-2 xs:pl-4 border-black border-2 overflow-hidden rounded-full bg-hit-pink">
                        <span
                          className={`block ${
                            device === `desktop` ? `caption` : `b2`
                          }`}
                        >
                          {cms.packSize}
                        </span>
                      </div>
                    )}
                  </div>
                )}

                <p className="w-full mt-v1 b2 sm:mb-5">
                  {cms.description ||
                    `Made from Paire’s supreme Cool Blend™ – Australian Merino wool
                  and sustainably sourced cotton, these socks are as good for
                  your toes as they are for your footprint.`}
                </p>

                {device === `desktop` ? (
                  <>
                    {colourOptionJSX}
                    {sizeOptionJSX}
                  </>
                ) : (
                  <>{sizeOptionJSX}</>
                )}

                <div className="w-full relative mt-3 sm:mt-0 sm:mb-10">
                  <Button
                    className={`w-full ${stocked ? `` : `pointer-events-none`}`}
                    color={stocked ? `black` : `white`}
                    onClick={() => {
                      if (!stocked) {
                        return;
                      }

                      addToCart(cartProduct, 1);
                    }}
                  >
                    <span className="f-button">
                      {stocked ? `Add to Bag` : `Sold out`}
                    </span>
                  </Button>
                </div>

                <Accordion />
              </article>
            </div>
          )}
        </section>

        <LottieAnimation
          id="feels-good-wave"
          animation={animationData}
          className="w-full relative block mt-v2"
          doLoad
          playing
        />

        <ProductFeaturesPDP />

        {cms.productOverviewBlocks.map((block, index) => {
          const key = `product-overview-${index}`;

          return (
            <div key={key}>
              <ProductOverview
                image={block.image.asset.fluid}
                imageAlt={block.image.altText}
                heading={block.heading}
                subheading={block.subheading}
                text={block.text}
                position={block.position ? `right` : `left`}
                backgroundColor={block.backgroundColour}
              />
            </div>
          );
        })}

        <CustomerReview reviews={cms.reviews} />

        <SuggestedProducts exclude={cms.id} productType={cms.productType} />

        <GivePaireCTA />
      </Layout>

      <Footer />
    </>
  );
};

ProductPage.propTypes = {
  location: PropTypes.shape({}).isRequired
};

export default ProductPage;

export const query = graphql`
  query ProductPage($id: String!) {
    sanityProduct(id: { eq: $id }) {
      id
      title
      packSize
      productType
      description
      reviews {
        customer
        text
      }

      shopifyProduct {
        id
        handle
        title
        description
        images {
          originalSrc
        }
        productType
        vendor
        variants {
          id
          sku
          title
          image {
            originalSrc
          }
          price
          selectedOptions {
            name
            value
          }
        }
      }

      availableColours {
        colourRef {
          hexes
          title
        }

        gallery {
          asset {
            fluid(maxWidth: 1440) {
              ...GatsbySanityImageFluid
            }
          }
          altText
        }
      }

      productOverviewBlocks {
        subHeading
        heading
        text
        position
        backgroundColour
        image {
          asset {
            fluid(maxWidth: 768) {
              ...GatsbySanityImageFluid
            }
          }
          altText
        }
      }
    }

    allSanityProduct {
      edges {
        node {
          title
          tagline
          description
          image {
            asset {
              fluid(maxWidth: 768) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }

    allShopifyProduct {
      edges {
        node {
          id
          title
          description
          handle
          images {
            originalSrc
          }
          variants {
            id
            sku
            title
            image {
              originalSrc
            }
            price
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }

    allShopifyAdminProduct {
      edges {
        node {
          products {
            alternative_id
            handle
            variants {
              alternative_id
              sku
              inventory_item_id
              title
            }
          }
        }
      }
    }

    allSanityColourMap {
      edges {
        node {
          hexes
          title
        }
      }
    }
  }
`;
