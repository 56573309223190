/* eslint-disable react/prop-types */

import React, { useContext, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { DocumentContext } from "~context/DocumentContext";
import Button from "~components/Button";
import Carousel from "~components/Carousel";
import { getVariantByOptions } from "~utils/shopify";

const SuggestedProducts = ({ exclude, productType }) => {
  const {
    addToCart,
    inventoryCache,
    inventoryEnabled,
    inventoryFetched
  } = useContext(DocumentContext);
  const { device } = useContext(DocumentContext);

  const [addEnabledProducts, setAddEnabledProducts] = useState({});

  const { allSanityColourMap, allSanityProduct } = useStaticQuery(graphql`
    query SuggestedProducts {
      allSanityColourMap {
        edges {
          node {
            hexes
            title
          }
        }
      }

      allSanityProduct {
        edges {
          node {
            id
            title
            handle
            priority
            frontPage
            productType
            packSize
            tagline

            image {
              asset {
                fluid(maxWidth: 400) {
                  ...GatsbySanityImageFluid
                }
              }
            }

            availableColours {
              colourRef {
                hexes
                title
              }

              gallery {
                asset {
                  fluid(maxWidth: 1440) {
                    ...GatsbySanityImageFluid
                  }
                }
                altText
              }
            }

            shopifyProduct {
              id
              title
              description
              handle
              images {
                originalSrc
              }
              productType
              vendor
              variants {
                id
                title
                image {
                  originalSrc
                }
                price
                selectedOptions {
                  name
                  value
                }
              }
            }
          }
        }
      }
    }
  `);

  //

  const relatedProducts = [];

  if (allSanityProduct?.edges?.[0]) {
    allSanityProduct.edges.forEach(({ node }) => {
      if (
        node?.id !== exclude &&
        node?.productType?.toLowerCase().trim() ===
          productType?.toLowerCase().trim() &&
        relatedProducts.length < 2
      ) {
        relatedProducts.push(node);
      }
    });
  }

  relatedProducts.sort((a, b) => {
    const priorityA = a.priority;
    const priorityB = b.priority;

    if (priorityA < priorityB) {
      return -1;
    }

    if (priorityA > priorityB) {
      return 1;
    }

    return 0;
  });

  const enableProductAdd = (handle, colour) => {
    const enabled = JSON.parse(JSON.stringify(addEnabledProducts));

    enabled[handle] = colour;

    setAddEnabledProducts(enabled);
  };

  //

  return (
    <section className="suggested-products w-full relative pt-v4 pb-v4 xs:py-10">
      <ul className="grid gap-col-2 mt-v4 xs:gap-row-2 sm:flex sm:flex-col sm:items-center">
        <div className="product-grid__item grid-end-3 xs:grid-end-12 xs:mb-12 sm:text-center xs:flex xs:flex-col xs:items-center">
          <h2 className="f3 mb-5">What about</h2>
          <h3 className="b2 xs:w-4/6">You might like a paire of these, two.</h3>
        </div>

        {device === `desktop` ? (
          <div className="grid-end-9 relative flex gap-3 xs:block">
            {relatedProducts.map(product => {
              const key = product.handle;

              if (!product?.shopifyProduct?.variants?.[0]) {
                return <React.Fragment key={key}></React.Fragment>;
              }

              //
              // variant buttons

              const colourButtons = [];
              const sizeButtons = [];

              product.shopifyProduct.variants.forEach(variant => {
                const options = variant.selectedOptions;

                options.forEach(option => {
                  switch (option.name.toLowerCase()) {
                    case `color`:
                    case `colour`:
                      if (!colourButtons.includes(option.value)) {
                        colourButtons.push(option.value);
                      }

                      break;

                    case `size`:
                      if (!sizeButtons.includes(option.value)) {
                        sizeButtons.push(option.value);
                      }

                      break;

                    default:
                      break;
                  }
                });
              });

              //
              // variant buttons (colours)

              const colourButtonJSX = [];

              let backgroundStyle = {};

              colourButtons.forEach(colourValue => {
                const selected =
                  addEnabledProducts?.[product.handle] === colourValue;

                let hexes;

                allSanityColourMap.edges.forEach(({ node }) => {
                  if (hexes) {
                    return;
                  }

                  if (node.title.toLowerCase() === colourValue.toLowerCase()) {
                    ({ hexes } = node);
                  }
                });

                //

                // TODO : auto-detect based on array length
                if (hexes?.[0]) {
                  if (hexes.length === 3) {
                    backgroundStyle = {
                      background: `linear-gradient(to right, ${hexes[0]} 33.333%, ${hexes[1]} 33.333%, ${hexes[1]} 66.667%, ${hexes[2]} 66.667%)`
                    };
                  } else if (hexes.length === 2) {
                    backgroundStyle = {
                      background: `linear-gradient(to right, ${hexes[0]} 50%, ${hexes[1]} 50%)`
                    };
                  } else {
                    backgroundStyle = {
                      backgroundColor: hexes[0]
                    };
                  }
                }

                colourButtonJSX.push(
                  <button
                    key={`${product.handle}-${colourValue}`}
                    type="button"
                    className={`product-page__color-picker w-8 h-8 mr-2 relative block rounded-full ${
                      device === `desktop` && selected ? `border-black` : ``
                    }`}
                    onClick={() =>
                      enableProductAdd(product.handle, colourValue)
                    }
                  >
                    <div
                      className="product-page__color-picker__bg w-full h-full relative rounded-full overflow-hidden pointer-events-none"
                      style={backgroundStyle}
                    ></div>
                  </button>
                );
              });

              //
              // variant buttons (sizes)

              const sizeButtonJSX = [];

              sizeButtons.forEach(sizeValue => {
                let buttonText;

                switch (sizeValue.toLowerCase()) {
                  case `s`:
                    buttonText = `SML`;
                    break;

                  case `m`:
                    buttonText = `MED`;
                    break;

                  case `l`:
                    buttonText = `LRG`;
                    break;

                  case `xl`:
                  default:
                    buttonText = `XLG`;

                    break;
                }

                let variantForSize;
                let inStock = false;

                if (addEnabledProducts?.[product.handle] && inventoryFetched) {
                  variantForSize = getVariantByOptions(product.shopifyProduct, {
                    Colour: addEnabledProducts?.[product.handle],
                    Size: sizeValue
                  });

                  inStock =
                    !inventoryEnabled ||
                    (variantForSize?.id &&
                      inventoryCache?.[variantForSize.id] &&
                      inventoryCache[variantForSize.id] > 0);
                }

                sizeButtonJSX.push(
                  <Button
                    className={`${
                      inStock ? `` : `opacity-25 pointer-events-none`
                    } relative block`}
                    onClick={() => {
                      if (variantForSize) {
                        addToCart({
                          product: product.shopifyProduct,
                          variant: variantForSize,
                          variants: null
                        });
                      }
                    }}
                    color="hit-pink"
                    transparent
                  >
                    <span
                      className={`${
                        device === `desktop` ? `f-button` : `b2 italic`
                      }`}
                    >
                      {buttonText}
                    </span>
                  </Button>
                );
              });

              //
              // button-based image parsing

              let image;
              let imageType = `default`;

              if (!addEnabledProducts?.[product.handle]) {
                // if the user hasn't clicked a colour button in this item
                if (product?.image?.asset?.fluid) {
                  image = product.image.asset.fluid;
                  imageType = `fluid`;
                } else if (product?.images?.[1]?.originalSrc) {
                  image = product?.images?.[1]?.originalSrc;
                }
              } else {
                // else the user has selected something and we should change the image to suit
                const colourKey = addEnabledProducts[product.handle];
                let fallbackImage;

                imageType = `fluid`;

                if (product?.availableColours?.[0]) {
                  product.availableColours.forEach(({ colourRef, gallery }) => {
                    if (image) {
                      return;
                    }

                    if (!fallbackImage && gallery?.[0]?.asset?.fluid) {
                      fallbackImage = gallery[0].asset.fluid;
                    }

                    if (colourRef?.title === colourKey && gallery?.[0]) {
                      image = gallery[0].asset.fluid;
                    }
                  });
                }

                if (!image) {
                  image = fallbackImage;
                }
              }

              const variantSelector = (
                <>
                  <div className="w-full flex flex-wrap mt-v1 xs:mt-4 mb-v1 xs:mb-4">
                    {colourButtonJSX}
                  </div>

                  {(!addEnabledProducts?.[product.handle] && (
                    <Link to={`/products/${product.handle}`}>
                      <Button
                        className="w-full relative block"
                        color="hit-pink"
                        transparent
                      >
                        <span className="f-button">View Product</span>
                      </Button>
                    </Link>
                  )) || (
                    <div className="products-page__hover-tray animation-appear w-full relative block">
                      <div className="products-page__hover-tray__shelf w-full absolute xs:relative top-0 xs:top-auto right-0 xs:right-auto bottom-0 xs:bottom-auto left-0 xs:left-auto z-10 flex justify-between xs:mb-2">
                        {sizeButtonJSX}
                      </div>

                      {(device === `desktop` && (
                        <Button
                          className="products-page__hover-tray__button w-full relative block pointer-events-none"
                          color="hit-pink"
                          transparent
                        >
                          <span className="f-button">Add to bag</span>
                        </Button>
                      )) || (
                        <Link to={`/products/${product.handle}`}>
                          <Button
                            className="products-page__hover-tray__button w-full relative block pointer-events-none"
                            color="hit-pink"
                            transparent
                          >
                            <span className="f-button">View product</span>
                          </Button>
                        </Link>
                      )}
                    </div>
                  )}
                </>
              );

              //

              return (
                <div
                  key={key}
                  className="w-1/2 xs:w-full relative mb-v2 xs:mb-16 pl-v1 xs:pl-0 pr-v1 xs:pr-0 pb-v1 xs:pb-0"
                >
                  <Link
                    to={`/products/${product.handle}`}
                    className="product-grid__item__image relative block rounded-edge overflow-hidden"
                  >
                    {(imageType === `fluid` && (
                      <figure className="w-full h-full absolute top-0 right-0 bottom-0 left-0 gpu">
                        <Img
                          className="w-full h-full relative object-cover"
                          fluid={image}
                          alt={product.handle}
                        />
                      </figure>
                    )) || (
                      <img
                        className="w-full absolute transform-center"
                        src={image}
                        alt={product.handle}
                      />
                    )}
                  </Link>

                  <article className="mt-v05 xs:mt-5">
                    <header className="w-full relative pt-v1">
                      <h2 className="mb-v1 xs:mb-3 f5 font-bold">
                        {product.title}
                      </h2>

                      <div className="flex items-center">
                        {product?.shopifyProduct?.variants?.[0]?.price && (
                          <h2 className="b1">
                            ${product.shopifyProduct.variants[0].price}
                          </h2>
                        )}

                        {product?.packSize && (
                          <div className="relative flex items-center justify-center ml-3 pt-2 pr-2 xs:pr-4 pb-1 xs:pb-1 pl-2 xs:pl-4 border-black border-2 overflow-hidden rounded-full bg-hit-pink">
                            <span className="block caption">
                              {product.packSize}
                            </span>
                          </div>
                        )}
                      </div>
                    </header>

                    {variantSelector}
                  </article>
                </div>
              );
            })}
          </div>
        ) : (
          <Carousel
            keyPrefix="suggested-products-gallery"
            items={relatedProducts.map((product, productIndex) => {
              const imageKey = `suggested-products-${productIndex}`;

              return (
                <Link to={product.slug} className="relative block">
                  <figure key={imageKey} className="w-full relative block">
                    {product?.image?.asset?.fluid && (
                      <Img
                        className="w-full relative block rounded-lg"
                        fluid={product.image.asset.fluid}
                        alt={product.handle}
                      />
                    )}
                  </figure>

                  <article className="mt-v05 xs:mt-5">
                    <header className="w-full relative justify-between pt-v1">
                      <h2 className="b1">{product.title}</h2>
                    </header>

                    <p className="w-full mt-v05 b2 xs:mt-2">
                      {product.tagline}
                    </p>

                    <div className="mt-v2 xs:mt-4">
                      <Button
                        className="w-full relative block xs:py-2"
                        color="hit-pink"
                        transparent
                      >
                        <span className="f-button">Shop Now</span>
                      </Button>
                    </div>
                  </article>
                </Link>
              );
            })}
            withBullets
          />
        )}
      </ul>
    </section>
  );
};

export default SuggestedProducts;
