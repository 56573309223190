import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

const Accordion = () => {
  const {
    allSanityAccordion: { edges: accordion }
  } = useStaticQuery(graphql`
    query {
      allSanityAccordion {
        edges {
          node {
            id
            name
            icon {
              asset {
                url
              }
            }
            text
          }
        }
      }
    }
  `);

  const [expanded, setExpanded] = useState({});

  return (
    <ul className="accordion w-full mt-v3">
      {accordion.map(({ node: item }) => {
        return (
          <AccordionLine
            key={item.id}
            {...item}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        );
      })}
    </ul>
  );
};

export default Accordion;

const AccordionLine = ({ id, name, icon, text, expanded, setExpanded }) => {
  const expand = () => {
    if (id === expanded) {
      setExpanded(``);
    } else {
      setExpanded(id);
    }
  };

  return (
    <li
      role="presentation"
      className="accordion__line border-t border-silver mb-1 cursor-pointer"
      onClick={expand}
      onKeyDown={expand}
    >
      <div className="w-full relative flex justify-between pt-v1 pb-v1 sm:py-3 select-none">
        <span className="relative flex items-center">
          <img src={icon.asset.url} alt="icon" />
          <p className="ml-v1 sm:ml-4 b2">{name}</p>
        </span>
        <span className="b2">More {expanded === id ? `-` : `+`}</span>
      </div>

      {expanded === id && (
        <div className="expander-panel animation-appear-down w-full relative block pb-v1">
          <p className="b2">{text}</p>
        </div>
      )}
    </li>
  );
};
